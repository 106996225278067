<template>
  <main class="Vehicles">
    <TheSubHeader
      icon="truck"
      title="Veículos"
      page-title-tag="Vehicles"
      type="filter"
      :actions="['addnew']"
      organizer-gap="1rem"
      organizer-columns="1fr"
    >
      <template #filter>
        <!-- Plate -->
        <BaseTreeSelect
          v-model="filter.plate"
          name="Placa"
          label="Placa"
          placeholder="Pesquisar"
          multiple
          allow-create
          @input="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="Vehicles__Content">
      <div class="Vehicles__Table">
        <DataTable
          :header="vehicles.data.headers"
          :table="vehicles.data.content"
          is-sticky
          @edit="editVehicle"
          @remove="openDeleteVehicle"
          @linkDriver="openLinkDriver"
          @unlinkDriver="openUnlinkDriver"
          @addDriver="addDriver"
          @showDriver="openDriverInfo"
        />
        <Pagination @page="search()" @paginate="search()" />
      </div>
    </div>
    <Dialog
      v-if="showDeleteVehicle"
      warning="Tem certeza que deseja remover esse veículo?"
      label-confirm="Sim"
      label-cancel="Não"
      @confirm="confirmDelete"
      @cancel="cancelDelete"
    />
    <Dialog
      v-if="showUnlinkVehicle"
      warning="Tem certeza que deseja desvincular o motorista desse veículo?"
      label-confirm="Sim"
      label-cancel="Não"
      @confirm="confirmUnlink"
      @cancel="cancelUnlink"
    />
    <Modal v-if="showLinkVehicle" class="Vehicles__Modal" @close="cancelLink">
      <template #header>
        <div class="Vehicles__ModalHeader">
          <span>Vincular motorista ao veículo: <strong>{{ vehiclePlate }}</strong></span>
        </div>
      </template>
      <template #body>
        <div class="Vehicles__ModalBody">
          <!-- Driver -->
          <BaseTreeSelect
            v-model="driverToLink"
            name="Motorista"
            label="Motorista"
            placeholder="Pesquisar"
            suggestions="searchDriversWithoutVehicle"
            @input="resetSearch()"
          />
          <BaseButton
            label="Vincular"
            theme="icon-left"
            filled
            color="primary-base"
            text-color="white"
            :disabled="driverToLink === null"
            @click="linkVehicle"
          />
        </div>
      </template>
    </Modal>
    <Modal v-if="showDriverInfo" class="Vehicles__Modal" @close="cancelDriverInfo">
      <template #header>
        <div class="Vehicles__ModalHeader">
          <span>Motorista vinculado ao veículo: <strong>{{ vehiclePlate }}</strong></span>
        </div>
      </template>
      <template #body>
        <div class="Vehicles__ModalBody">
          <p><strong>Nome:</strong> {{ driverInfo.name }}</p>
          <p><strong>Telefone:</strong> {{ DriverInfoPhoneMasked }}</p>
          <p><strong>Email:</strong> {{ driverInfo.email }}</p>
        </div>
      </template>
    </Modal>
  </main>
</template>

<script>
import { BaseTreeSelect, BaseButton } from '@/components/atoms'
import { Pagination, Dialog, DataTable, FiltersTag, Modal } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { mapActions, mapState } from 'vuex'
import Mask from '@/plugins/mask/Mask.js'
import { vehicles } from '@/api'

export default {
  name: 'Vehicles',
  components: {
    DataTable,
    BaseButton,
    BaseTreeSelect,
    TheSubHeader,
    Pagination,
    Dialog,
    FiltersTag,
    Modal
  },
  data() {
    return {
      filter: {
        id: [],
        plate: [],
      },
      driverToLink: null,
      driverToUnlink: null,
      vehicleId: null,
      driverInfo: {
        name: null,
        phone: null,
        email: null,
      },
      showDeleteVehicle: false,
      showUnlinkVehicle: false,
      showLinkVehicle: false,
      showDriverInfo: false,
      vehiclePlate: null,
      allTags: []
    }
  },

  computed:{
    ...mapState({
      transporters: state => state.transporters.transporters,
      vehicles: state => state.vehicles.vehicles,
      stores: state => state.stores.stores,
      queryString: state => state.queryString.queryString,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
    }),

    DriverInfoPhoneMasked() {
      return this.driverInfo.phone ? Mask.phone(this.driverInfo.phone) : null
    },

    filteredPlate() {
      return this.filter.plate
    }
  },
  
  mounted() {
    this.search()
  },

  methods:{
    ...mapActions({
      fetchVehicles: 'vehicles/fetchVehicles',
      deleteVehicle: 'vehicles/deleteVehicle',
      setParameters: 'queryString/setParameters',
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination'
    }),

    editVehicle(row) {
      this.$router.push({
        path: `/vehicles/${row.id}`
      })
    },

    openDeleteVehicle(row) {
      this.vehicleId = row.id
      this.showDeleteVehicle = true
    },

    openLinkDriver(row) {
      this.driverToLink = null
      this.vehicleId = row.id
      this.vehiclePlate = Mask.carPlate(row.plate)
      this.showLinkVehicle = true
    },

    openUnlinkDriver(row) {
      this.vehicleId = row.id
      this.driverToUnlink = row.driver_id
      this.showUnlinkVehicle = true
    },

    openDriverInfo(row) {
      this.vehiclePlate = Mask.carPlate(row.plate)
      this.driverInfo.name = row['driver.name']
      this.driverInfo.email = row['driver.email']
      this.driverInfo.phone = row['driver.phone']
      this.showDriverInfo = true
    },

    addDriver(row) {
      this.$router.push({
        path: '/drivers/add',
        query: {
          vehicleId: row.id
        }
      })
    },

    linkVehicle() {
      let data = {
        vehicle_id: this.vehicleId,
        driver_id: this.driverToLink.id
      }
      vehicles.linkVehicle(data, () => {
        this.showLinkVehicle = false
        this.search()
      }, (e) => e)
    },

    unlinkVehicle() {
      let data = {
        driver_id: this.driverToUnlink,
      }
      vehicles.unlinkVehicle(data, () => {
        this.showUnlinkVehicle = false
        this.search()
      }, (e) => e)
    },

    confirmDelete() {
      this.deleteVehicle({ id: this.vehicleId })
      this.showDeleteVehicle = false
    },

    cancelDelete() {
      this.showDeleteVehicle = false
    },

    confirmUnlink() {
      this.unlinkVehicle()
      this.showUnlinkVehicle = false
    },

    cancelUnlink() {
      this.showUnlinkVehicle = false
    },

    cancelLink() {
      this.showLinkVehicle = false
    },

    cancelDriverInfo() {
      this.showDriverInfo = false
    },

    updateTags(){
      this.allTags = [...this.filter.id, ...this.filter.plate]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filter.id = this.filter.id.filter((tag) => tag.name !== name)
      this.filter.plate = this.filter.plate.filter((tag) => tag.name !== name)
      

      this.resetSearch()
    },

    clearAllTags(){
      this.filter.allTags = []
      this.filter.id = []
      this.filter.plate = []

      this.resetSearch()
    },

    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    search(){

      const params = {
        obj: {
          // driver_id: this.filter.id,
          plate: this.filteredPlate
        },
        paginate: this.paginate,
        page: this.page
      }
      
      this.updateTags()
      this.setParameters(this.filter)
      this.fetchVehicles(params).then(() => this.setPagination(this.vehicles))
      
    },
  }
}
</script>

<style lang="scss" scoped>
.Vehicles {
  &__Content{
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
  }
  &__Table{
    grid-area: main;
    margin-bottom: 50px;
  }
}
</style>
<style lang="scss">
.Vehicles {
  &__Table{
    .DataTable__tr .DataTableTd:nth-of-type(5) .DataTable--limit,
    .DataTable__tr .DataTableTd:nth-of-type(7) .DataTable--limit {
      max-width: initial;
    }
  }
  &__Modal {
    .Modal__container {
      height: auto;
      max-height: 90%;
      width: auto;
      max-width: 90%;
      overflow: initial;
      width: 100%;
      max-width: 420px;
    }
    .Modal__containerBody {
      height: auto;

      p {
        margin: 0 0 5px;
      }
    }
  }
  &__ModalHeader {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 3rem 0 0.5rem;
  }
  &__ModalBody {
    padding: 1rem;
    height: auto;
    display: flex;
    flex-direction: column;

    .searchDrivers {
      margin-bottom: 20px !important;
    }
  }
}
</style>